import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HeaderSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "home-feature-steaks.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className="page-header"
          fluid={imageData}
          backgroundColor="#f2f3f3"
        >
          <Row>
            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }} className="feature-bg">
              <h2>
                Thanks
              </h2>
            </Col>
          </Row>
        </BackgroundImage>
      )
    }}
  />
)

const ThanksPage = () => (
  <Layout pageInfo={{ pageName: "contact" }}>

    <SEO title="Contact" keywords={[
      `flame and hog`,
      `hog roast`,
      `lamb roast`,
      `bbq catering`
    ]} />

    <div className="grey-bg">

      <HeaderSection/>

      <Container>
        <Row className="global-padding text-center">
          <Col md={{ span: 8, offset: 2 }}>
            <p>
              We aim to respond to all emails within 24 hours.
            </p>
          </Col>
        </Row>
      </Container>
    </div>

  </Layout>
)

export default ThanksPage
